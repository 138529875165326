@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full font-montserrat text-charcoal selection:bg-[rgba(223,60,177,0.25)];
  }
}

@layer utilities {
  input:focus + .search,
  input:not(:placeholder-shown) + .search {
    transform: translate(0.25rem, -50%) scale(0.8);
    @apply rounded-full px-2 py-0.5 m-0 ml-1 bg-platinum text-charcoal font-medium lg:bg-spreen-green w-fit;
  }

  .underline-hover {
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }
}
